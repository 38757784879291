@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 3s infinite;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: white;
  padding: "8px";
}

.left-half,
.right-half {
  height: 100vh;
  overflow: hidden;
}

.left-half {
  width: 70vw;
}

.right-half {
  width: 54vw;
}

.half-image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.left-half .half-image {
  clip-path: inset(0 30% 0 0);
}

.right-half .half-image {
  clip-path: inset(0 0 0 65%);
}

.rotate {
  animation: rotate 10s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
