.zoom {
  padding: 50px;
  transition: transform 0.2s; /* Animation */
  width: "50px";
  height: "50px";
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(
    2.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
